<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <b-avatar
                :src="this.veri.musteriAvatar"
                :text="avatarText(this.veri.customer)"
                variant="light-primary"
                size="104px"
                rounded/>

            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ this.veri.customer }}
                </h4>
              </div>
              <div class="d-flex flex-wrap">
                <b-button variant="primary " @click="$refs.refInputEl.click()">
                  <input accept="image/jpeg,image/jpg" @input="ImagetoBase64" ref="refInputEl" type="file"
                         class="d-none">
                  <span class="d-none d-sm-inline">Lgonuzu Ekleyin</span>
                  <feather-icon icon="EditIcon" class="d-inline d-sm-none"/>

                </b-button>
                <b-button variant="outline-danger" @click="avatarSil" class="ml-1">
                  Sil
                </b-button>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center mt-2">
            <div class="d-flex align-items-center mr-2">
              <b-avatar variant="light-primary" rounded>
                <feather-icon icon="AwardIcon" size="18"/>
              </b-avatar>
              <div class="ml-1">
                <h5 class="mb-0">
                  {{ this.veri.bakiye }}
                </h5>
                <small>Güncel Bakiyeniz</small>
              </div>
            </div>


          </div>

        </b-col>
        <b-col cols="12" xl="6">
          <div class="d-flex justify-content-start">
            <b-avatar
                :src="this.veri.plasiyerAvatar"
                :text="avatarText(this.veri.plasiyer)"
                variant="light-primary"
                size="104px"
                rounded/>
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  Müşteri Temsilciniz
                </h4>
                <span class="card-text">{{ this.veri.plasiyer }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button variant="primary">
                  <span><feather-icon icon="PhoneIcon" class="mr-50"/></span>
                  444 5 497
                </b-button>
              </div>
              <hr/>
              <div class="d-flex flex-wrap">
                <b-button variant="primary" size="sm">
                  <span><feather-icon icon="PhoneIcon" class="mr-50"/></span>
                  {{ this.veri.plasiyerNumara }}
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col md="6" style="overflow: scroll; max-height: 600px">
        <b-card title="Son Yapılan 30 İşlem">
          <app-timeline>
            <app-timeline-item :icon="item.icon" v-for="item in sonislemler">
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ item.islem }}</h6>
                <small class="text-muted">{{ item.tarih }}</small>
              </div>
              <p>{{ item.aciklama }}</p>
              <p>
                <b-button :href="item.link" target="_blank" variant="outline-primary">
                  Göster
                </b-button>
              </p>
            </app-timeline-item>
          </app-timeline>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-button-toolbar class="justify-content-center">
          <b-button-group size="sm">
            <b-button @click="kategoriDegistirt(item)" variant="flat-primary" v-for="item in kategoriler">
              {{ item }}
            </b-button>
          </b-button-group>
        </b-button-toolbar>
        <hr/>
        <b-row style="overflow: scroll;  max-height: 600px">
          <b-col md="6" v-for="product in urunler" :key="product.urunId">
            <b-card class="ecommerce-card" no-body>
              <div class="item-img text-center mt-2">
                <img :src="product.urunLink" class="img-thumbnail" alt="Cinque Terre" width="75" height="100">
              </div>
              <b-card-body>
                <div class="item-wrapper">
                  <div>
                    <h6 class="item-price">
                      <b-badge class="profile-badge" variant="light-primary">
                        {{ product.urunFiyatiTl }}
                      </b-badge>
                    </h6>
                  </div>
                </div>
                <hr/>
                <h6 class="item-name">
                  {{ product.urunAdi }}
                </h6>
                <hr/>
                <b-card-text class="item-description">
                  <b-badge v-if="product.urunAciklama" class="profile-badge" variant="light-primary">
                    {{ product.urunAciklama }}
                  </b-badge>
                </b-card-text>
              </b-card-body>
              <div class="item-options text-center">
                <div class="item-wrapper">
                  <div class="item-cost">
                    <h4 class="item-price">
                      <b-form-spinbutton :min="product.minimumMiktar" v-model="product.urunMiktari" class="ml-75"
                                         inline/>
                    </h4>
                  </div>
                </div>
                <b-row class="mb-1 pr-2 pl-2" align-h="around">
                  <b-button variant="light" tag="a" class="btn-wishlist" @click="HizliSiparis(product)">
                    <feather-icon icon="FastForwardIcon" class="mr-50"/>
                    <span>Hızlı Sipariş</span>
                  </b-button>
                  <b-button variant="primary" tag="a" class="btn-cart" @click="SepeteEkle(product)">
                    <feather-icon icon="ShoppingCartIcon" class="mr-50"/>
                    <span>Sepete Ekle</span>
                  </b-button>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-alert
        @dismissed="cookKaydet"
        v-if="cook"
        class="position-fixed fixed-bottom m-1 rounded-bottom"
        style="z-index: 2000; height: 50px"
        variant="info"
        show
        dismissible
        fade>
      <div class="alert-body" v-html="cook">
      </div>
    </b-alert>
  </div>
</template>

<script>
import {avatarText} from '@core/utils/filter'
import store from "@/store";
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem";
import {dangerToast, successToast} from "@/views/Services/ToastServices";
import {cookieInfo} from '../Services/CookieJob'
import Cookies from "js-cookie";
import {getItems} from "@/views/Services/CartUpdate";
import Compressor from 'compressorjs';


export default {
  components: {AppTimelineItem, AppTimeline},
  computed: {
    veri: {
      get() {
        let vv = store.getters['loginStoreModule/getUserBilgileri'];
        if (vv.guid)
          this.load(vv.guid)
        return vv
      },
    }
  },
  async mounted() {
    this.cook = await cookieInfo()
  },
  methods: {
    async kategoriDegistirt(val) {
      this.urunler = this.urunlerRaw.filter((i) => i.kategoriAdi == val);
    },
    async compress(file) {
      let that = this;
      new Compressor(file, {
        quality: 0.1,
        success(result) {
          let reader = new FileReader();
          reader.readAsDataURL(result);
          reader.onloadend = function () {
            let base64data = reader.result;
            that.veri.musteriAvatar = base64data;
            that.$http.post("AvatarEkleV1", {
              "Id": that.veri.guid,
              "Base64": base64data
            })
          }
        },
        error(err) {
          console.log(err.message);
        },
      });
    },
    async avatarSil() {
      this.veri.musteriAvatar = '';
      await this.$http.post("AvatarSilV1", {
        "Id": this.veri.guid,
      })
    },
    async ImagetoBase64() {
      let file = this.$refs.refInputEl.files[0];
      await this.compress(file);
    },
    async cookKaydet() {
      Cookies.set("portalCookie", "true", {expires: 30})
    },
    async load(id) {
      try {
        let sonYapilanIslemler = await this.$http.get("SonYapilanIslemlerV1/" + id)
        if (sonYapilanIslemler.status === 200) this.sonislemler = sonYapilanIslemler.data;
        else dangerToast(this, "Hata", "Son yapılan işlemleri alırken hata oluştu tekrar deneyiniz");
        let urunler = await this.$http.get("AlinanUrunlerV1/" + id)
        if (urunler.status === 200) {
          this.urunler = urunler.data;
          this.urunlerRaw = urunler.data;
          this.kategoriler = [...new Set(urunler.data.map(obj => obj.kategoriAdi))];
          this.seciliKategori = this.kategoriler[0];
          this.kategoriDegistirt(this.seciliKategori)
        } else dangerToast(this, "Hata", "Ürün bilgilerini alırken hata oluştu tekrar deneyiniz");
      } catch (e) {
        dangerToast(this, "Hata", "İçsel bir hata oluştu tekrar deneyiniz");
      }
    },
    async HizliSiparis(val) {
      try {
        let gonder = await this.$http.post("HizliSiparisV1", {
          "Id": this.veri.guid,
          "UrunId": val.urunId,
          "UrunMiktari": val.urunMiktari
        });
        if (gonder.status == 200 && gonder.data == true) successToast(this, "Başarılı", "Hızlı Siparişiniz alınmıştır.")
        else dangerToast(this, "Hata", "Siparişiniz alınırken hata alındı, lütfen tekrar deneyin.")
      } catch (e) {
        dangerToast(this, "Hata", "İçsel bir hata oluştu")
      }
    },
    async SepeteEkle(val) {
      try {
        let gonder = await this.$http.post("SepeteEkleV1", {
          "Id": this.veri.guid,
          "UrunId": val.urunId,
          "UrunMiktari": val.urunMiktari
        });
        if (gonder.status == 200 && gonder.data == true) successToast(this, "Başarılı", "Ürün sepetinize eklenmiştir.")
        else dangerToast(this, "Hata", "Ürün sepetinize eklenirken hata alındı, lütfen tekrar deneyin.")
        await getItems(this.veri.guid);
      } catch (e) {
        dangerToast(this, "Hata", "İçsel bir hata oluştu")
      }
    },
  },
  watch: {},
  data() {
    return {
      avatarText,
      cookShow: false,
      sonislemler: [],
      urunler: [],
      urunlerRaw: [],
      kategoriler: [],
      seciliKategori: [],
      userData: [],
      cook: ''
    }
  },

}
</script>
<style lang="scss">
.ecommerce-card {
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 25px 0 rgba(#22292f, 0.25);
  }

  // ratings
  .item-rating {
    ul {
      margin-bottom: 0;
    }

    svg,
    i {
      height: 1.143rem;
      width: 1.143rem;
      font-size: 1.143rem;
    }
  }

  // Item name
  .item-name {
    margin-bottom: 0;

    a {
      font-weight: 600;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .item-description {
    font-size: 0.875rem;
  }

  // buttons style
  .btn-wishlist,
  .btn-cart {
    span {
      vertical-align: text-top;
    }

    i,
    svg {
      margin-right: 0.25rem;
      vertical-align: text-top;

      &.text-danger {
        fill: #dc3545;
      }
    }
  }
}
</style>
